import React from 'react'
import { InlineWidget } from "react-calendly";
import Layout from '../../../components/layout';
import Img from "gatsby-image"
import Logo from "../../../images/logo-04.png";
import LeadForm from '../../../components/leadForm/LeadForm';
// import './book-demo.css'
import { Helmet } from 'react-helmet';


const BookDemo = ({data}) => {
  const feature6 = data.feature6.childImageSharp.fluid
  return (
<Layout>
  <Helmet>
   <script type="text/javascript" src="https://app.99inbound.com/99inbound.js"></script>
  </Helmet>
      <div className="demo-header_nav">
        
            <div className="merchants-header_textContainer">
                <div style={{display:'flex', marginBottom:'0.5rem', justifyContent:'center'}}>
                    <img src={Logo} alt="logo"  style={{width:'3rem', margin:'0', cursor:'pointer'}} />
                    <h1 className="navbar-logo_text" style={{fontSize:'3rem'}}>Sizify</h1>
                </div>
                <h2 className="merchants-featured_title" style={{textAlign:'center'}}>Request Your Demo Today</h2>

                <h3 className="merchants-featured_subTitle" style={{textAlign:'center'}}>
                Let's discuss your needs and how we can help.
                </h3>
        
            </div>
        </div>
      <div className="demo-section_bg" >
              <div className="demo-header_container " >
             
                <div className="demo-feature_textContainer" >
                <h3 className="demo-calendar-widget_subTitle" style={{marginBottom:'0.25rem'}}>
                      *Pricing available for stores of all sizes
                    </h3>
                
                <h2 className="demo-calendar_title">Want to improve sizing & recover up to 28% of loss revenue? </h2>

                  <h3 className="demo-calendar_subTitle">
                  Learn more:
                  </h3>
                  <ul className='demo-checklist-ul'>
                    <li className="demo-checklist-li">
                    <div>
                     <svg style={{marginRight:"0.8rem"}} width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="checkboxIconTitle" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" > <title id="checkboxIconTitle">Checkbox (selected)</title> <rect stroke="#CFDDFC" fill='#CFDDFC'   x="21" y="3" width="18" height="18" rx="1" transform="rotate(90 21 3)"/> <path stroke="#7D9FE8"  d="M6.66666 12.6667L9.99999 16L17.3333 8.66669"/> </svg>
                     </div>
                     <p>How we leverage sizing technology to help maximize your ROI</p>
                      </li>
                      <li className="demo-checklist-li">
                    <div>
                     <svg style={{marginRight:"0.8rem"}} width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="checkboxIconTitle" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" > <title id="checkboxIconTitle">Checkbox (selected)</title> <rect stroke="#CFDDFC" fill='#CFDDFC'   x="21" y="3" width="18" height="18" rx="1" transform="rotate(90 21 3)"/> <path stroke="#7D9FE8"  d="M6.66666 12.6667L9.99999 16L17.3333 8.66669"/> </svg>
                     </div>
                     <p>How to optimize sizing data to improve your store's shopping experience</p>
                      </li>
                      <li className="demo-checklist-li">
                    <div>
                     <svg style={{marginRight:"0.8rem"}} width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="checkboxIconTitle" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" > <title id="checkboxIconTitle">Checkbox (selected)</title> <rect stroke="#CFDDFC" fill='#CFDDFC'   x="21" y="3" width="18" height="18" rx="1" transform="rotate(90 21 3)"/> <path stroke="#7D9FE8"  d="M6.66666 12.6667L9.99999 16L17.3333 8.66669"/> </svg>
                     </div>
                     <p> Discuss your store's specific sizing needs & how we can help meet them</p>
                      </li>
                      <li className="demo-checklist-li">
                    <div>
                     <svg style={{marginRight:"0.8rem"}} width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="checkboxIconTitle" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" > <title id="checkboxIconTitle">Checkbox (selected)</title> <rect stroke="#CFDDFC" fill='#CFDDFC'   x="21" y="3" width="18" height="18" rx="1" transform="rotate(90 21 3)"/> <path stroke="#7D9FE8"  d="M6.66666 12.6667L9.99999 16L17.3333 8.66669"/> </svg>
                     </div>
                     <p> How we seamlessly integrate with your store to make onboarding easy </p>
                      </li>
                   
                    </ul>
                
                </div>
                <div className="demo-header_imageContainer" >
                  <div className='demo-calendar-widget-container'>
                  {/* <h2 className="demo-calendar-widget_title ">Let's Talk</h2>

                    <h3 className="demo-calendar-widget_subTitle" style={{marginBottom:'0.25rem'}}>
                      Schedule your meeting
                    </h3> */}
                    {/* <div className="demo-header_container" style={{padding:'0', flexDirection:'row', alignItems:'center'}}>
                      <svg role="img" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" aria-labelledby="timeIconTitle" stroke="#7d9fe8" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#7d9fe8"> <title id="timeIconTitle">Time</title> <circle cx="12" cy="12" r="10"/> <polyline points="12 5 12 12 16 16"/> </svg>
                      <h3 className="demo-calendar-widget_subTitle" style={{marginBottom:'0'}}>
                        15 mins
                      </h3>
                    </div> */}
                    <LeadForm  formLink={'https://app.99inbound.com/api/e/GA5R-TDE'} title={"Let's Talk"} time={'15 mins'}/>



                 
                      {/* <InlineWidget url="https://calendly.com/sizify/lets-talk?hide_event_type_details=1&hide_gdpr_banner=1&background_color=7d9fe8&text_color=ffffff&primary_color=ffffff" /> */}

                  </div>
            
                </div>
              </div>
          
        </div>
        <div className='demo-social-proof_container' >
            <h2 className="demo-social-proof_title">
            Trusted by Over 600 Shopify Apparel Brands
            </h2>
            <div className="merchants-header_imageContainer" style={{padding:"0 3rem"}}>
                <Img  
                fluid={feature6}
                />
              </div>
        </div>

       

  </Layout>
  )
}

export default BookDemo

export const query = graphql`
query {
    feature6: file(relativePath: { eq: "brands-slice.png" }) {
      childImageSharp {
        fluid(quality:100){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`