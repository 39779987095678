import React,{useState, useRef } from 'react'
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const LeadForm = ({formLink, title, time }) => {
    const [successMessage, setSuccessMessage] = useState(0)
    const formEl = useRef(null);
    // const doHtmlFormPost = values => {
    //   formEl.current.submit();
    // };
      const formik = useFormik({
          initialValues: {
            name:'',
            companyName:'',
            email: '',
            phoneNumber:''
          },
          validationSchema: Yup.object({
            name: Yup
            .string()
            .required('Required'),
            companyName: Yup
            .string()
            .required('Required'),
            email: Yup
            .string()
            .email()
            .required('Required'),
            phoneNumber: Yup
            .number()
            .required('Required')
            .integer(),
            websiteURL: Yup
            .string()
            .required('Required'),
            phoneNumber: Yup
            .string()
            .required('Required'),
            infoBox: Yup
            .string()
          }),
          onSubmit: values => {
            console.log(values)
            fetch(
                formLink,
                {
                  method: "POST",
                  headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                  body: JSON.stringify(values),
                }
              ).then((res)=>{
                  console.log(res)
                  setSuccessMessage(1)
                // doHtmlFormPost()
              })
              .catch((e) => { 
              console.log(e)
              setSuccessMessage(2)
            });
           
          },
        });
 if(successMessage === 1){
     return (
         <div>
             Thank you for your submission. A representative will contact you shortly.
         </div>
     )
     
 }
 if(successMessage === 2){
    return (
        <div>
            Error occurred while submitting form. Please contact support.
        </div>
    )
}
  return (
    <form className='growthChecklist-ctaEmailContainer'method="POST" onSubmit={formik.handleSubmit} >

        <h3 className="demo-calendar_subTitle" style={{marginBottom:'0.25rem'}}>
            {title}
        </h3>
        <div className="demo-header_container" style={{padding:'0', flexDirection:'row', alignItems:'center', margin:'0 0 1rem 0'}}>
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" aria-labelledby="timeIconTitle" stroke="#7d9fe8" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#7d9fe8"> <title id="timeIconTitle">Time</title> <circle cx="12" cy="12" r="10"/> <polyline points="12 5 12 12 16 16"/> </svg>
            <h3 className="demo-calendar-widget_subTitle" style={{marginBottom:'0'}}>
            {time}
            </h3>
        </div>
      <div style={{width:'100%'}}>
      <h3 className="demo-calendar_subTitle">
            Name
        </h3>
        <input
          className='growthChecklist-ctaInput'
          type="text"
          id='name'
          name='name'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          placeholder="eg. Joe Smith"

        />
        {formik.touched.name && formik.errors.name ? (
          <div style={{color:'red'}}>{formik.errors.name}</div>
        ) : null}
      </div>
      <div style={{width:'100%'}}>
      <h3 className="demo-calendar_subTitle">
            Company Name
        </h3>
          <input
            className='growthChecklist-ctaInput'
            type="text"
            id='companyName'
            name='companyName'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            placeholder="eg. Nike"
        />
        {formik.touched.companyName && formik.errors.companyName ? (
          <div style={{color:'red'}}>{formik.errors.companyName}</div>
        ) : null}
      </div>
      <div style={{width:'100%'}}>
      <h3 className="demo-calendar_subTitle">
            Email
        </h3>
        <input
          className='growthChecklist-ctaInput'
          type="email"
          id='email'
          name='email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          placeholder="eg. matthew@example.com"
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
          <div style={{color:'red'}}>{formik.errors.phoneNumber}</div>
        ) : null}
        </div>
        <div style={{width:'100%'}}>
        <h3 className="demo-calendar_subTitle">
            Phone Number
        </h3>
        <input
          className='growthChecklist-ctaInput'
          type="text"
          id='phoneNumber'
          name='phoneNumber'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phoneNumber}
          placeholder="eg. 6478957849"
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
          <div style={{color:'red'}}>{formik.errors.phoneNumber}</div>
        ) : null}
        </div>
        <div style={{width:'100%'}}>
        <h3 className="demo-calendar_subTitle">
            Website URL
        </h3>
        <input
          className='growthChecklist-ctaInput'
          type="text"
          id='websiteURL'
          name='websiteURL'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.websiteURL}
          placeholder="www.example.com"
        />
        {formik.touched.websiteURL && formik.errors.websiteURL ? (
          <div style={{color:'red'}}>{formik.errors.websiteURL}</div>
        ) : null}
        </div>
        <div style={{width:'100%'}}>
        <h3 className="demo-calendar_subTitle">
        Tell us what you're looking to discuss on this call.
        </h3>
        <input
          className='growthChecklist-ctaInput'
          type="text"
          id='infoBox'
          name='infoBox'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.infoBox}
          placeholder=""
        />
        {formik.touched.infoBox && formik.errors.infoBox ? (
          <div style={{color:'red'}}>{formik.errors.infoBox}</div>
        ) : null}
        </div>
      <button className='growthChecklist-ctaButton' type="submit" >
       Submit
      </button>
  </form>
  )
}

export default LeadForm